import React from "react"
import { Link } from 'gatsby';

// Component imports
import Layout from "../components/Layout"
import Card from '../components/Card';
import SEO from '../components/SEO';
import PageHeader from '../components/PageHeader';

// Image imports
import estate from '../images/estate-photo.jpg';

// Routes for URLs
import * as ROUTES from '../constants/routes';

const Estate = () => {
  return (
    <Layout>
        <SEO 
            title="Estate Planning in Kansas City | Mike Martin Law"
            description="Mike Martin has been providing estate planning services for 35 years."
        />
        <Card>
            <PageHeader 
                title="Estate Planning"
                image={estate}
                alte="Middle-aged couple"
            />
            <div className="grid col-span-1 sm:grid-cols-12 my-8">
                <div className="sm:col-span-8">
                    <p>Mike Martin Law can handle all your estate planning needs, including powers of attorney, wills, living wills and trusts. Because Mike Martin has extensive experience handling difficult probate cases, he is uniquely qualified to help you avoid problems and pitfalls associated with end-of-life-planning. He knows what can go wrong with estate planning, and he can help ensure that your estate is handled the way you intend.</p>
                    <p>If you are looking for an estate planning attorney, you can count on Mike Martin Law to attend to your end-of-life planning with sensitivity and expertise.</p>
                    <ul>
                        <li>Complete estate planning, including powers of attorney, trusts, wills, and living wills.</li>
                        <li>Experience working with a wide variety of situations that require sophisticated estate planning, such as domestic partnerships, planning for the care of disabled children, and providing for pets after death.</li>
                        <li>With more than 35 years experience handling estates and probate cases, Mike Martin has a strong network of attorneys and experts to consult with regarding unique or complex estate planning needs.</li>
                    </ul>
                    <h3 className="section-header">What about fees?</h3>
                    <p>Whenever possible, Mike Martin Law utilizes flat fees for the standard components of estate planning, including most wills and trusts. This allows you to make an informed decision on what estate plan to select and encourages questions.</p>
                    <p>When a client is charged on an hourly basis (or by the minute) by an estate planning lawyer, he or she may be discouraged from calling and asking questions. By charging a flat fee, Mike Martin encourages as many telephone calls, emails, and meetings as are necessary to complete the project properly.</p>
                    <h3 className="section-header">Why estate planning is essential</h3>
                    <p>Proper estate planning ensures that your final wishes are legally binding and gives you peace of mind that your property will be distributed to your chosen heirs exactly as you intend.</p>
                    <p>In addition, estate planning permits you to save as much as possible on taxes and court costs. Proper planning also removes uncertainty and gives your family peace of mind during a time of grief.</p>
                    <p>All estate plans should include, at minimum, two important instruments:</p>
                    <ul>
                        <li><span className="font-bold">Durable Power of Attorney.</span> The power of attorney is a document for managing your property or health care during your life, in case you are ever unable to do so yourself.</li>
                        <li><span className="font-bold">Will.</span> Your will provides for the management and distribution of your property after death. Beyond a will, many people use <Link to={ROUTES.TRUSTS} className="text-secondary underline">trusts</Link> (sometimes called “living trusts,” or “loving trusts”). If properly established, trusts can easily and efficiently transfer property after death.</li>
                    </ul>
                    <p className="font-bold">Estate planning should be a priority, regardless of your age or the size of your estate. If you need an estate planning lawyer, <Link to={ROUTES.CONTACT} className="text-secondary underline">contact Mike today.</Link></p>
                    <p>Are you involved in a contested will, estate, or trust? Mike Martin is also an expert in <Link to={ROUTES.PROBATE} className="text-secondary underline">probate law.</Link></p>
                </div>
            </div>
        </Card>
    </Layout>
  )
}

export default Estate;